import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import sal from 'sal.js';
import '../styles/sal.css';

// Components
import ThreeTwo from '../images/three-two';
import { ShortVideo } from '../videos/short-video';

const ProjectContainer = styled.div`
  position: relative;
  grid-column-end: ${props => props.column};
  grid-row-end: ${props => props.rowColumn};

  & .slide-up-animation {
    width: 100%;
    height: 100%;
  }

  & .inner-container {
    overflow: hidden;

    width: 100%;
    height: 100%;
  }

  &:hover .title {
    opacity: 1;
    transform: translateY(0);
  }

  & img {
    z-index: 2;
    transition: 250ms all ease;
  }

  &:hover img {
    transform: scale(1.1);
    opacity: ${props => (props.fadeOutImage ? 0 : 1)};
  }
`;

const Title = styled.div`
  position: absolute;
  z-index: 10;

  opacity: ${props => props.opacity};

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;

  width: calc(100% - 20px);

  top: 0;
  left: 0;

  padding: 10px;
  color: #fff;

  transition: 450ms all ease;
  transform: translateY(${props => props.translateY});

  & > div {
    width: calc(100% - 25px);
    line-height: 1.25;
  }

  & h1 {
    font-size: ${props => props.h2Size};
  }

  & h2 {
    font-size: ${props => props.h3Size};
  }

  & button {
    font-size: 30px;
    color: #fff;

    width: 18px;
    height: 18px;
    line-height: 18px;

    display: ${props => props.buttonDisplay};
  }
`;

const Video = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  height: 100%;

  z-index: 1;

  overflow: hidden;
`;

const Project = ({ project, index }) => {
  const [currentVideoPlayerId, setcurrentVideoPlayerId] = useState(null);
  const [shouldImageFadeOut, setImageFadeOut] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      sal({
        threshold: 0.2,
        once: true,
      });
    }
  }, []);

  return (
    <ProjectContainer
      fadeOutImage={
        currentVideoPlayerId === project.node.prismicId && shouldImageFadeOut
      }
      column={
        project.node.data.image_size === 'Small' ||
        project.node.data.image_size === 'Portrait'
          ? `span 1`
          : `span 2`
      }
      rowColumn={
        project.node.data.image_size === 'Portrait' ||
        project.node.data.image_size === 'Large'
          ? `span 2`
          : `span 1`
      }
      onMouseOver={() => setcurrentVideoPlayerId(project.node.prismicId)}
      onMouseLeave={() => setcurrentVideoPlayerId(null)}
    >
      <div
        data-sal="slide-up"
        data-sal-duration="450"
        data-sal-easing="ease-out-quad"
        className="slide-up-animation"
      >
        <Link to={`/project/${project.node.uid}`}>
          <div className="inner-container">
            <Title
              className="title"
              opacity={0}
              h2Size={isMobile === true ? `14px` : `22px`}
              h3Size={isMobile === true ? `12px` : `20px`}
              buttonDisplay={isMobile === true ? `none` : `block`}
              translateY={`20px`}
            >
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: project.node.data.title.html,
                  }}
                ></div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: project.node.data.subtitle.html,
                  }}
                ></div>

                {/* <h2>{project.node.data.title.text}</h2> */}
                {/* <h3>{project.node.data.subtitle.text}</h3> */}
              </div>
              <button>+</button>
            </Title>
            <ThreeTwo
              image={project.node.data.image.url}
              setSize={project.node.data.image_size}
              alt={project.node.data.image.alt}
              width={project.node.data.image.dimensions.width}
              height={project.node.data.image.dimensions.height}
            />
            {!isMobile && (
              <Video>
                <ShortVideo
                  url={project.node.data.video.url}
                  setImageFadeOut={shouldImageFadeOut =>
                    setImageFadeOut(shouldImageFadeOut)
                  }
                  preload={index <= 2 ? 'metadata' : 'none'}
                  isPlaying={
                    currentVideoPlayerId === project.node.prismicId
                      ? true
                      : false
                  }
                />
              </Video>
            )}
          </div>
        </Link>
      </div>
    </ProjectContainer>
  );
};

export default Project;
