import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

// Components
import Project from '../components/work/project';

const WorkContainer = styled.div`
  position: relative;

  padding: 60px 0 0 0;
  margin: 0 auto;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 45px;
  grid-auto-flow: dense;

  @media (min-width: 1500px) {
    max-width: 1400px;
  }

  @media (max-width: 1499px) {
    max-width: 1050px;
  }

  @media (max-width: 767px) {
    padding: 15px 0 0 0;

    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
  }
`;

const Work = ({ data }) => {
  const projects = data.allPrismicProject.edges.map((project, index) => (
    <Project
      project={project}
      index={index}
      key={`project_${project.node.prismicId}_${index}`}
    />
  ));

  return (
    <>
      <Helmet title={`Work - Treatment Studio`} />
      <WorkContainer>{projects}</WorkContainer>
    </>
  );
};
export default withPrismicPreview(Work);

export const query = graphql`
  {
    allPrismicProject(sort: { fields: data___date, order: DESC }) {
      edges {
        node {
          uid
          prismicId
          data {
            title {
              text
              html
            }
            subtitle {
              text
              html
            }
            video {
              url
            }
            image {
              alt
              url(imgixParams: { auto: "format" })
              dimensions {
                width
                height
              }
            }
            image_size
          }
        }
      }
    }
  }
`;
